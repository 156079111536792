// PUBLIC ROUTES
export const ABOUT = "/about/";
export const CONTACT = "/contact/";
export const PRIVACY = "/privacy/";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions/";



export const ARTICLE_LIST = "/grassroots-spotlight/";
export const ARTICLE_LIST_OLD = "/news/";
export const ARTICLE = "/grassroots-spotlight/:articlePath";
export const ARTICLE_OLD = "/news/:articlePath";

export const ELECTIONS = "/elections/";
export const ELECTION_DETAILS = "/elections/:electionDateId";
export const ELECTION_CANDIDATE_DETAILS = "/elections/:electionId/candidates/:politicianId";
export const AGENCY_DETAILS = "/commissions/:agencyId";
export const AGENCY_ELECTED_OFFICIALS = "/commissions/:agencyId/elected-officials";
export const AGENCY_ELECTED_OFFICIAL_DETAILS = "/commissions/:agencyId/elected-officials/:electedOfficialId";


// MAPS
export const MAPS = "/maps";
export const ALL_MAPS = `${MAPS}/list/`;
export const MAP_PAGE = `${MAPS}/:mapSlug/`;
export const LOCAL_GOVERNMENT = "/local-government";
export const LOCAL_GOVERNMENT_PAGE = `${LOCAL_GOVERNMENT}/:mapSlug`;

export const VOTING_LOCATIONS = "/voting-locations";


// BAD PLACE TO BE
export const NO_ACCESS = "/no-access";
export const SUSPENDED = "/suspended";
export const NOT_ACTIVATED = "/activate";


// USER ROUTES
export const PROFILE = "/user-profile";
export const SUBSCRIBE = "/subscribe";
export const SUBSCRIPTION_PENDING = "/subscription-welcome";
export const USER_VERIFIED = "/user-verified";

// EDITOR ROUTES

export const ARTICLE_CREATE = "/grassroots-spotlight/create";
export const ARTICLE_EDIT = "/grassroots-spotlight/:articlePath/edit";
export const ARTICLE_CREATE_OLD = "/news/create";
export const ARTICLE_EDIT_OLD = "/news/:articlePath/edit";
export const EDITOR_DASHBOARD = "/editor/";
export const ELECTION_CREATE = "/election/create";
export const ELECTION_EDIT = "/election/:electionId/edit";
export const ADMIN_VOTING_LOCATIONS = "/admin/voting-locations";
export const ADMIN_VOTING_LOCATIONS_CREATE = "/admin/voting-locations";
