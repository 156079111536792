import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ArticlePreview.scss";
import moment from "moment";
import Icon from "@src/components/shared/elements/Icon";
import Image from "@src/components/shared/elements/Image";


function ArticlePreview({ article }) {
    const publishedDate = article.published ? moment(article.publishedDate).format("MMMM DD, YYYY") : "DRAFT - NOT PUBLISHED";
    const authorName = "Williamson County Citizens"; // article.author?.fullName || "";
    return (
        <div className={styles.previewWrapper}>
            <Link className={classnames(styles.root, {
                [styles.draft]: !article.published,
            })} to={`/grassroots-spotlight/${article.path}/`}>
                <Image src={article?.teaserImage?.src} />
                <div className={styles.inner}>
                    <div className={styles.date}>{publishedDate}</div>
                    <h3>{article.headline}</h3>
                    <div className={styles.author}>By {authorName}</div>
                    <div className={styles.bodyText}>{article.summary}</div>
                </div>
            </Link>
            {!article.published && <Link to={`/grassroots-spotlight/${article.path}/edit/`} className={styles.editBtn} ><Icon iconName={"pencil"} circle colorHex={"#999999"} /></Link>}
        </div>
    );
}


ArticlePreview.propTypes = {
    article: PropTypes.object
}

export default ArticlePreview;
