import React from "react";
import styles from "./MapSection.scss";
import MapListContent from "@src/components/maps/MapListContent";

function MapSection({}) {
    return (
        <div className={styles.root}>
            <h2>Find Your Representatives</h2>
            <div className={styles.text} >Explore how your local government<br /> works and learn about your representatives.</div>
            <div className={styles.mapContent}>
                <MapListContent isHomepage />
            </div>
        </div>
    );
}

MapSection.propTypes = {}

export default MapSection;
